
import { computed, defineComponent } from 'vue'
import { useStore } from '../../store'
import GenericHeader from '../headers/GenericHeader.vue'
import GenericFooter from '../footers/GenericFooter.vue'
import LoadingOverlay from '../LoadingOverlay.vue'

export default defineComponent({
	name: 'GenericLayout',
	components: {
		GenericHeader,
		GenericFooter,
		LoadingOverlay
	},
	setup() {
		const store = useStore()
		const isLoading = computed((): boolean => store.state.isLoading)

		return {
			isLoading
		}
	}
})
