<template>
	<div class="view-wrapper">
		<GenericHeader></GenericHeader>
		<slot />
		<transition name="fade">
			<LoadingOverlay v-show="isLoading" />
		</transition>
		<GenericFooter></GenericFooter>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from '../../store'
import GenericHeader from '../headers/GenericHeader.vue'
import GenericFooter from '../footers/GenericFooter.vue'
import LoadingOverlay from '../LoadingOverlay.vue'

export default defineComponent({
	name: 'GenericLayout',
	components: {
		GenericHeader,
		GenericFooter,
		LoadingOverlay
	},
	setup() {
		const store = useStore()
		const isLoading = computed((): boolean => store.state.isLoading)

		return {
			isLoading
		}
	}
})
</script>

<style lang="scss" scoped>
.view-wrapper {
	background: $aqua-gradient;
}
</style>
