
import { defineComponent } from 'vue'
import { useStore } from '../store'
import GenericLayout from '../global-components/layouts/GenericLayout.vue'

export default defineComponent({
	name: 'SimplePayPageNotFoundView',
	components: {
		GenericLayout
	},
	setup() {
		const store = useStore()

		store.dispatch('hideLoadingScreen')

		return {}
	}
})
