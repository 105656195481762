<template>
	<generic-layout>
		<el-main class="form-wrapper">
			<div>
				<h1>Page Not Found</h1>
				<p>
					The page you requested does not exist, please try again with
					a valid url.
				</p>
			</div>
		</el-main>
	</generic-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '../store'
import GenericLayout from '../global-components/layouts/GenericLayout.vue'

export default defineComponent({
	name: 'SimplePayPageNotFoundView',
	components: {
		GenericLayout
	},
	setup() {
		const store = useStore()

		store.dispatch('hideLoadingScreen')

		return {}
	}
})
</script>
