<template>
	<el-header height="54px">
		<div class="header-container">
			<img :src="iqProLogo" alt="IQ Pro logo" />
		</div>
	</el-header>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '../../store'

export default defineComponent({
	name: 'GenericHeader',
	setup() {
		const store = useStore()
		const iqProLogo = computed((): string => {
			return store.state.logos.iqProLogoNavy
		})

		return { iqProLogo }
	}
})
</script>

<style lang="scss" scoped>
header {
	background: $navy;
	width: 100vw;
	box-shadow: 0px -3px 6px 3px $shadow-gray;

	:deep(.header-container) {
		max-width: 800px;
		margin: auto;
	}

	:deep(img) {
		height: 34px;
		margin: 10px 0;
	}
}
</style>
