
import { defineComponent, computed } from 'vue'
import { useStore } from '../../store'

export default defineComponent({
	name: 'GenericHeader',
	setup() {
		const store = useStore()
		const iqProLogo = computed((): string => {
			return store.state.logos.iqProLogoNavy
		})

		return { iqProLogo }
	}
})
